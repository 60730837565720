var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"list-unstyled"},[_c('li',{class:_vm.$style.item},[_c('div',{staticClass:"mb-2",class:_vm.$style.itemHead},[_c('div',{class:_vm.$style.itemPic},[_c('img',{attrs:{"src":"resources/images/avatars/1.jpg","alt":"Jamie Rockstar"}})]),_vm._m(0)]),_vm._m(1),_vm._m(2)]),_c('li',{class:_vm.$style.item},[_c('div',{staticClass:"mb-2",class:_vm.$style.itemHead},[_c('div',{class:_vm.$style.itemPic},[_c('img',{attrs:{"src":"resources/images/avatars/2.jpg","alt":"Mary Stanform"}})]),_vm._m(3)]),_vm._m(4),_vm._m(5)]),_c('li',{class:_vm.$style.item},[_c('div',{staticClass:"mb-2",class:_vm.$style.itemHead},[_c('div',{class:_vm.$style.itemPic},[_c('img',{attrs:{"src":"resources/images/avatars/5.jpg","alt":"Jess Hofner"}})]),_vm._m(6)]),_vm._m(7),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-2"},[_c('div',[_vm._v("Jamie Rockstar")]),_c('div',{staticClass:"text-muted"},[_vm._v("Backoffice Agent")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-muted mb-2"},[_vm._v(" Access Level: "),_c('strong',{staticClass:"text-gray-6"},[_vm._v("Level 7")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar bg-success",staticStyle:{"width":"70%"},attrs:{"role":"progressbar","aria-valuenow":"{70}","aria-valuemin":"{0}","aria-valuemax":"{100}"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-2"},[_c('div',[_vm._v("Mary Stanform")]),_c('div',{staticClass:"text-muted"},[_vm._v("Developer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-muted mb-2"},[_vm._v(" Access Level: "),_c('strong',{staticClass:"text-gray-6"},[_vm._v("Level 4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar bg-primary",staticStyle:{"width":"40%"},attrs:{"role":"progressbar","aria-valuenow":"{40}","aria-valuemin":"{0}","aria-valuemax":"{100}"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-2"},[_c('div',[_vm._v("Jess Hofner")]),_c('div',{staticClass:"text-muted"},[_vm._v("CEO")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-muted mb-2"},[_vm._v(" Access Level: "),_c('strong',{staticClass:"text-gray-6"},[_vm._v("Level 9")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar bg-danger",staticStyle:{"width":"90%"},attrs:{"role":"progressbar","aria-valuenow":"{90}","aria-valuemin":"{0}","aria-valuemax":"{100}"}})])
}]

export { render, staticRenderFns }